var isMobile = mobileAndTabletcheck();
var scrollTop = 0;

$(function(){

	$("[data-qr-box]").fancybox({
		// Options will go here
		// type : 'inline',
		smallBtn : true,
		afterShow : function( instance, current ) {
			console.info( 'done!' );
			$("html").addClass('noscrolling');
		},
		afterClose: function( instance, current ) {
			$("html").removeClass('noscrolling');
		},
	});

	$("[data-fancybox]").fancybox({
		// Options will go here
		// type : 'inline',
		smallBtn : true,
	});

	$("a[href='" + location.protocol+'//'+location.host+location.pathname + "']").addClass('active').parents('.menu-item').addClass('active');
    
	$(window).resize(function(){
		$(".anchor").css('top', $("header").outerHeight() * -1);
	}).trigger('resize');

	$(window).scroll(function(){
		scrollTop = $(window).scrollTop();
	}).trigger('scroll');
	
})
$(window).on('load', function() {
	$(".preload").removeClass("preload");
	$(".anim").addClass("ready");
	$(window).trigger('scroll');
})